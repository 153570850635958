// Modal css\

.custom-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 99;
}
.custom-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  background: #fff;
  overflow: initial;
  border-radius: 4px;
  outline: none;
  .box_inner {
    height: 75vh;
    width: 968px;
    overflow-y: auto;

    @media (max-width: 1600px) {
      width: 1070px;
    }
    @media (max-width: 1400px) {
      width: 900px;
    }
    @media (max-width: 992px) {
      width: 700px;
    }
    @media (max-width: 768px) {
      width: 560px;
    }
    @media (max-width: 599px) {
      width: 460px;
    }
    @media (max-width: 490px) {
      width: 360px;
    }
    @media (max-width: 420px) {
      width: 320px;
    }
  }
}

.ReactModal__Overlay {
  opacity: 0;
  transition: all 0.3s ease;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.close-modal {
  background: transparent;
  border: none;
  display: flex;
  width: 45px;
  height: 45px;
  position: absolute;
  z-index: 4;
  right: -50px;
  top: -32px;
  @media screen and (max-width: 1199px) {
    right: 0px;
    top: -60px;
  }
  @media screen and (max-width: 575px) {
    right: -10px;
    top: -37px;
  }
  img {
    width: 45px;
    height: 45px;
    cursor: pointer;
    position: absolute;
    filter: invert(1);
    transition: 0.3s;
    @media screen and (max-width: 575px) {
      width: 30px;
      height: 30px;
    }
  }
  &:hover {
    img {
      filter: invert(0.8);
    }
  }
}
