@charset "utf-8";

body.dark {
  background-color: #1d1b1b;
  color: #a7afbd;
}
body.dark h1,
body.dark h2,
body.dark h3,
body.dark h4,
body.dark h5,
body.dark h6 {
  color: #fff;
}
body.dark ::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #a0a4a9;
}
body.dark :-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #a0a4a9;
  opacity: 1;
}
body.dark ::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #a0a4a9;
  opacity: 1;
}
body.dark :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #a0a4a9;
}
body.dark ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #a0a4a9;
}
body.dark ::placeholder {
  /* Most modern browsers support this now. */
  color: #a0a4a9;
}
body.dark {
  scrollbar-color: #494b50 #494b50;
}
body.dark:-webkit-scrollbar-track {
  background: #494b50;
}
body.dark::-webkit-scrollbar-thumb {
  background-color: #494b50;
  border-color: #1d1b1b;
}
body.dark .modalbox .description_wrap {
  scrollbar-color: #5c5d64 #5c5d64;
}
body.dark .modalbox .description_wrap:-webkit-scrollbar-track,
body.dark .fixed_content .mainbar:-webkit-scrollbar-track {
  background: #5c5d64;
}
body.dark .modalbox .description_wrap::-webkit-scrollbar-thumb,
body.dark .fixed_content .mainbar::-webkit-scrollbar-thumb {
  background-color: #5c5d64;
  border-color: #5c5d64;
}
body.dark .contact .wrapper .right .input_list ul li input {
  color: #a0a4a9;
  border: 1px solid rgba(255, 255, 255, 0.45);
}
body.dark .contact .wrapper .right .input_list ul li input:focus {
  border: 1px solid rgba(255, 255, 255, 0.3);
}
body.dark .contact .message_area textarea {
  color: #a0a4a9;
  border: 1px solid rgba(255, 255, 255, 0.45);
}
body.dark .contact .message_area textarea:focus {
  border: 1px solid rgba(255, 255, 255, 0.3);
}
body.dark .hero .background_shape {
  background-color: #34353a;
}
body.dark .video .circle:after {
  background-color: rgba(255, 255, 255, 0.03);
}
body.dark .hero .main_menu ul li {
  background-color: #494b50;
  box-shadow: none;
}
body.dark .modalbox .description_wrap {
  background: #494b50;
}
body.dark .hero .main_menu ul li.react-tabs__tab--selected,
body.dark .fixed_content .menubar ul li.react-tabs__tab--selected {
  background-color: $color-blue-primary;
}

body.dark .portfolio .portfolio_filter li {
  color: #fff;
}
body.dark .portfolio .portfolio_filter li.react-tabs__tab--selected {
  color: $color-blue-primary;
}
body.dark .hero .main_menu ul li span {
  color: #fff;
}
body.dark .mainpart {
  background-color: #494b50;
  box-shadow: none;
}
body.dark .about .right .bigger_text p {
  color: #d3d7df;
}
body.dark .copyright .right ul li a {
  color: #a7afbd;
}
body.dark .copyright .right ul li a:hover {
  color: $color-blue-primary;
}
body.dark .copyright .left a {
  color: #a7afbd;
}
body.dark .copyright .left a:hover {
  color: $color-blue-primary;
}
body.dark .resume .info_list ul li .short .job span {
  color: #d3d7df;
}
body.dark .progress_inner .background {
  background: #777a7f;
}
body.dark .circular_progress_bar .list_inner:after {
  border-color: #777a7f;
}
body.dark .progress_inner span.label {
  color: #fff;
}
body.dark .portfolio .portfolio_filter ul li a {
  color: #fff;
}
body.dark .portfolio .portfolio_filter ul li a:hover,
body.dark .portfolio .portfolio_filter ul li a.current {
  color: $color-blue-primary;
}
body.dark .services .service_list ul li .list_inner {
  background-color: #5c5d64;
  box-shadow: none;
}
body.dark .services .service_list ul li .list_inner:hover {
  box-shadow: none;
}
body.dark .testimonials .testimonials_list .list_inner {
  background-color: #5b5c64;
  border-radius: 15px;
  padding: 0;
}
body.dark .testimonials .testimonials_list .slick-slide {
  padding: 0 15px;
}
body.dark .news .news_list ul li .details .title {
  color: #fff;
}
body.dark .testimonials .testimonials_list .list_inner .in {
  box-shadow: none;
}
body.dark .news .news_list ul li .details .title a {
  color: #fff;
}
body.dark .news .news_list ul li .details .title a:hover {
  color: $color-blue-primary;
}
body.dark .news .news_list ul li .details {
  background-color: #5b5c64;
  box-shadow: none;
}
body.dark .modalbox .box_inner {
  background-color: #494b50;
}
body.dark .modalbox .main_details .detailbox .first {
  color: #fff;
}
body.dark .modalbox .main_details .detailbox span a {
  color: #a7afbd;
}
body.dark .hero_second .background_shape {
  background-color: #3a3934;
}
body.dark .extra_menu .list_in .center {
  background-color: #494b50;
  box-shadow: none;
}
body.dark .extra_menu .list_in .list_icon {
  box-shadow: none;
}
body.dark .extra_menu .list_in .down_icon {
  box-shadow: none;
}
body.dark .extra_menu .list_in .center span {
  color: #fff;
}
body.dark
  .extra_menu
  .menu_list
  .unordered
  .unorderest_li.opened
  .list_in
  .down_icon,
body.dark
  .extra_menu
  .menu_list
  .unordered
  .unorderest_li
  .list_in:hover
  .down_icon {
  background-color: #494b50;
}
body.dark
  .extra_menu
  .menu_list
  .unordered
  .unorderest_li.opened
  .list_in
  .down_icon
  .svg,
body.dark
  .extra_menu
  .menu_list
  .unordered
  .unorderest_li
  .list_in:hover
  .down_icon
  .svg {
  color: #848484;
}
body.dark .extra_menu .menu_list .unordered .unorderest_li .details_wrapper {
  background-color: #494b50;
  box-shadow: none;
}
body.dark .fixed_content .menubar ul li {
  background-color: #494b50;
}
body.dark .fixed_content .menubar ul li:hover {
  background-color: $color-blue-primary;
}
body.dark .fixed_content .menubar ul li.active {
  background-color: $color-blue-primary;
}
body.dark .fixed_content .menubar ul li span {
  color: #fff;
}
body.dark .fixed_content .infobar .extra {
  background-color: #494b50;
}
body.dark .fixed_content .mainbar {
  background-color: #494b50;
}
body.dark .fixed_content .infobar .extra .short span {
  color: #a7afbd;
}
body.dark .fixed_content .testimonials .testimonials_list .list_inner {
  padding: 30px;
}
body.dark .contact .wrapper .left ul li .list_inner .short span a {
  color: #a7afbd;
}
body.dark .contact .wrapper .left ul li .list_inner .short span a:hover {
  color: $color-blue-primary;
}
@media (max-width: 480px) {
  body.dark .news .news_list ul li .details {
    background: none;
    box-shadow: none;
  }
}

body.dark .iextra_demo .itopbar {
  background-color: #3a3934;
}
body.dark .navbar-toggler span {
  background: #fff;
}
body.dark .isection_new {
  background-color: #494b50;
  box-shadow: none;
}
body.dark .iextra_demo .background_part {
  background-color: #3a3934;
}
body.dark .iextra_demo .top_shape,
body.dark .iextra_demo .bottom_shape {
  background-color: #3a3934;
}
body.dark .isidebar_3 {
  background-color: #494b50;
}
body.dark .isidebar_3 .menu ul li span {
  color: #fff;
}
body.dark .isidebar_3 .menu ul li.current span,
body.dark .isidebar_3 .menu ul li:hover span {
  color: $color-blue-primary;
}
body.dark .isidebar_3 .image .main {
  border-color: #494b50;
  box-shadow: 18px 18px 30px rgba(255, 255, 255, 0.08);
}
body.dark .isidebar_3 .menu ul li:before {
  background-color: rgba(255, 255, 255, 0.05);
}
body.dark .isidebar_3 .menu ul li:after {
  background-color: rgba(255, 255, 255, 0.1);
}
body.dark .iextra_demo_2 .top_shape,
body.dark .iextra_demo_2 .bottom_shape {
  background-color: #3a3934;
}
body.dark .imainpart_new_2 .main_menu ul li {
  background-color: #494b50;
  box-shadow: none;
  &:hover {
    span {
      color: #fff;
    }
  }
}
